import logo from './logo.svg'
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarComp from './components/NavbarComp';
import Login from './components/Login';
import { Route, Routes } from 'react-router-dom';


function App() {
  return (
    <div className="App" style={{
      backgroundColor: 'black',
    }}>
      
    <Login></Login>
    </div>
  );
}

export default App;
