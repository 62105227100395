import React, { Component } from "react";
import { Navbar, Nav, FormControl, Button, Form } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes,Link } from "react-router-dom";
import PendingOrder  from "./PendingOrder";
import PrepFill from "./PrepFill";
import Ship from "./Ship";
import '../Navbar.css';
import logo from '../NutrienLogo.png';

export default function NavbarComp() {
//   const loggedInUser = localStorage.getItem("userLoggedInCity");
//   if(loggedInUser == undefined || loggedInUser==null )
//   {
//     return (
//       <div>
// Please go back to login page again
// </div>
//     );
  
//     }
//     else{
    return (
//  <div>
//         <div className="Navbar">
//           <Navbar variant={"dark"} expand="xl">
//             <Navbar.Brand href="#home">
//               <img src={logo} alt="..." height="36"></img>
//             </Navbar.Brand>
//             <Navbar.Toggle aria-controls="basic-navbar-nav" />
//             <Navbar.Collapse id="basic-navbar-nav">
//               <Nav className="links">
//                 <Nav.Link className="links" as={Link} to={"/PendingOrder"}>
//                   Pending
//                 </Nav.Link>
//                 <Nav.Link as={Link} to={"/prepfill"}>
//                   PrepFill
//                 </Nav.Link>
//                 <Nav.Link as={Link} to={"/ship"}>
//                   Ship
//                 </Nav.Link>
//               </Nav>
              
//             </Navbar.Collapse>
//           </Navbar>
//         </div>

        <div>
          <Routes>
            <Route path="/" element={<PendingOrder />} />
            <Route path="/prepfill" element={<PrepFill />} />
            <Route path="/ship" element={<Ship />} />
          </Routes>
        </div>
    
    );
}

 

