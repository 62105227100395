import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { OrderTable } from "./OrderTable.js"
import { BrowserRouter as Router, Route, Routes,Link } from "react-router-dom";
import { Navbar, Nav, FormControl, Button, Form } from "react-bootstrap";
import logo from '../NutrienLogo.png';
import ReactPlayer from 'react-player';

var OrderTableText = {
  color : 'white',
}


export default class PrepFill extends Component {
  constructor(props) {
    super(props);
    this.state = { Orders: [], addModalShow: false, editModalShow: false, deadLink: false };
  }
  LogoutClicked= () =>
  {
    localStorage.clear();
    window.location.href = 'https://aqutracwb.com/';
  
  }
  refreshList = () => {
    const LoggedInCityId = localStorage.getItem("userLoggedInCityId");
    if(LoggedInCityId == 100000000010)
    {
    fetch(
      process.env.REACT_APP_PREP_CLOVERDALE
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ deadLink: false })
        this.setState({ Orders: data });
      },
      (error) => {
        if (error) {
          this.setState({ deadLink: true })
        }});
      }
      else
      {
        fetch(
          process.env.REACT_APP_PREP_SALEM
        )
          .then((response) => response.json())
          .then((data) => {
            
            this.setState({ deadLink: false })
            this.setState({ Orders: data });
          },
          (error) => {
            if (error) {
              this.setState({ deadLink: true })
            }});
      }
  }

  componentDidMount() {
    this.refreshList()
    this.interval = setInterval(this.refreshList, 5000)
    this.pageRefresh = setInterval(this.pageReload, 60000)
  }

  componentWillUnmount(){
    clearInterval(this.interval)
  }
  pageReload = () => {
    window.location.reload();
  }
  render() {
    const { Orders } = this.state;
    const loggedInUser = localStorage.getItem("userLoggedInCity");
    if(loggedInUser == undefined || loggedInUser==null )
    {
      window.location.href = 'https://aqutracwb.com/';
    }
    let addModalClose = () => this.setState({ addModalShow: false });
    let editModalClose = () => this.setState({ editModalShow: false });
    return (
      <div style={{
        backgroundColor: 'black',
        color:'white'
      }}>
        <div className="Navbar">
          <Navbar variant={"dark"} expand="xl">
            <Navbar.Brand href="#home">
              <img src={logo} alt="..." height="36"></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="links">
                <Nav.Link as={Link} to={"/PendingOrder"}>
                  Pending
                </Nav.Link>
                <Nav.Link className="links" as={Link} to={"/prepfill"}>
                  PrepFill
                </Nav.Link>
                <Nav.Link as={Link} to={"/ship"}>
                  Ship
                </Nav.Link>
              </Nav>
              <div className="links"  style={{width:"76%", textAlign:"center", color:"darkorange"}}>Location: {loggedInUser}</div>            
             <div className="links"  style={{textAlign:"right", marginRight:"20px"}}>
             <button className="logout-button" onClick={this.LogoutClicked}>Logout</button> 
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div id="thisIsDivForVideo" style={{height:"1px",width:"0.1%"}}>
        <ReactPlayer url="https://www.youtube.com/watch?v=JobTQTR5LO0&ab_channel=TCSGlobal"
            width="100%"
            pip={true}
            ref="vidRef" 
            playing={true}
            controls={true}
            muted={true}
          //  playing={this.state.playingVideo}
          ></ReactPlayer>
          </div>
        <Table striped bordered responsive>
          <thead className="headerColorForTable">
            <tr>
            <th style={OrderTableText}>Status</th>
              <th style={OrderTableText}>Carrier</th>              
              <th style={OrderTableText}>W/0 Number</th>
              <th style={OrderTableText}>Order Date</th>
              <th style={OrderTableText}>Mix</th>
              <th style={OrderTableText}>Color</th>
              <th style={OrderTableText}>Container Type</th>
              <th style={OrderTableText}>Gal</th>
              <th style={OrderTableText}>Ship to</th>
            </tr>
          </thead>
          {!this.state.deadLink &&
            <OrderTable Orders={Orders}/>
          }
        </Table>
        {this.state.deadLink &&
          <h1>An error occurred while fetching data. Please contact an administrator</h1>}
      </div>
    );
  }
}