import React, { Component } from "react";
import '../Navbar.css';
var OrderTableText = {
  color : 'white',
}

const oStatus = {
  5:"PrepFill Started",
  6:"PrepFill Completed",
  2:"Pending",
  7:"Ready to Ship"
}

export function OrderTable(props) {
    return (
      <tbody>
        {props.Orders.map((order) => (
          <tr className={order.whiteboardstatus==5? 'newclassfortest': 'OrderTableTextNew2'} key={order.workordernumber}>
            <td style={OrderTableText}>{oStatus[order.whiteboardstatus]}</td>
            <td style={OrderTableText}>{order.carriername}</td>
            <td style={OrderTableText}>{order.workordernumber}</td>
            <td style={OrderTableText}>{order.orderdate.substring(0,10)}</td>
            <td style={OrderTableText}>{order.mix}</td>
            <td style={OrderTableText}>{order.colorCode}</td>
            <td style={OrderTableText}>{order.containertype}</td>
            <td style={OrderTableText}>{order.totalGL}</td>
            <td style={OrderTableText}>{order.shipto}</td>
          </tr>
        ))}
      </tbody>
    );
}

//<tr className={order.workordernumber>30000? 'OrderTableTextNew2': 'OrderTableText1'} key={order.workordernumber}>