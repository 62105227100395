import "../components/LoginUI.css";
import profile from "../Images/login.jpg";
import email from "../Images/Usersssss.png";
import pass from "../Images/pass.png";
import { useState } from "react";
import axios from "axios";
import { useNavigate,Navigate  } from "react-router-dom";
import React, { Component } from "react";
import { Redirect, Route } from "react-router";

export const UserDetails = React.createContext();

export default function Login() {
   
 
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
    const [error, seterror] = useState("");
    const [loading, setLoading] = useState("");
   
    const navigate = useNavigate();
   
    const handleApi= () =>
    {
      
      
      axios
      .post("https://www.myaqutrac.com/WhiteBoardAPIApp/UserLogin/Login", {
        //.post("https://localhost:44338/UserLogin/Login", {
        Email: username,
        Password: password,
       })
       .then((result) => {
        var data = result.data;
        var companyID= data.companyid;
        // history.push("/NavbarComp");
        //<Navigate to="/NavbarComp" />
        if(companyID==100000000010)
        {
          localStorage.clear();
          localStorage.setItem('userLoggedInCity', 'Cloverdale')
          localStorage.setItem('userLoggedInCityId', '100000000010')
        }
        else if(companyID==100000003461){
          localStorage.clear();
          localStorage.setItem('userLoggedInCity', 'Salem')
          localStorage.setItem('userLoggedInCityId', '100000003461')
        }
        else{
          localStorage.clear();
          navigate("/NavbarComp", { replace: true });
          return;
        }
        navigate("/NavbarComp", { replace: true });
          //console.log(result);
          //alert('Success')
       })
      .catch((error) => {
         console.log(error);
          alert("Invalid username and password");
        });
    
    }
    
    console.log({ username, password })

  return (
    
    <div className="main">
      <div className="sub-main">
        <div>
          <div className="imgs">
            <div className="container-image">
              <img src={profile} alt="profile" className="profile" />
            </div>
          </div>
          <div>
            <h1>Login Page</h1>
            <div>
              <img src={email} alt="email" className="email" />
              <input
                type="text"
                placeholder="Username"
                className="name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="second-input">
              <img src={pass} alt="pass" className="email" />
              <input
                type="password"
                placeholder="Password"
                className="name"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="login-button">
              <button onClick={handleApi}>Login</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

